// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"

import * as bootstrap from 'bootstrap'

import Trix from "trix"
global.Trix = Trix;
window.Trix = Trix;

import { WOW } from 'wowjs'

// import jsPDF from 'jsPDF'
import { jsPDF } from "jspdf";
global.jsPDF = jsPDF;
window.jsPDF = jsPDF;

import './alignment-elements'
import './trix-editor-overrides'
import './counter'
import './sliders'

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

//= require commontator/application

Rails.start()
ActiveStorage.start()

// WOW active
new WOW().init();

require("trix")
require("@rails/actiontext")