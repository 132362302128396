import counterUp from 'counterup2'

const callback = entries => {
    entries.forEach(entry => {
        const el = entry.target
        if (entry.isIntersecting) {
            counterUp(el, {
                duration: 1000,
                delay: 32,
            })
        }
    })
}

const IO = new IntersectionObserver(callback, {threshold: 1})

document.addEventListener('DOMContentLoaded', function (event) {
    const elts = document.querySelectorAll('.counter')
    elts.forEach(function (el) {
        IO.observe(el)
    })
})