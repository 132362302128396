import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [
        "disability",
        "disability_detail", "disability_detail_field",
        "billed",
        "billing_company_info_field",
        "billing_company", "billing_company_field",
        "billing_address", "billing_address_field",
        "billing_postcode", "billing_postcode_field",
        "billing_town", "billing_town_field",
        "billing_firstname", "billing_firstname_field",
        "billing_lastname", "billing_lastname_field",
        "billing_phone", "billing_phone_field",
        "billing_email", "billing_email_field",
        "payment_method",
        "virement_details",
        "cb_details",
        "lunch",
        "facture_template"
    ]

    connect() {
    }

    toggleDisability(event) {
        if (event.target.value == 'oui') {
            this.disabilityTarget.checked = true
            this.disability_detail_fieldTarget.classList.remove('d-none')
        } else {
            this.disabilityTarget.checked = false
            this.disability_detail_fieldTarget.classList.add('d-none')
        }
    }

    toggleLunch(event) {
        if (event.target.value == 'oui') {
            this.lunchTarget.checked = true
        } else {
            this.lunchTarget.checked = false
        }
    }

    toggleBilled(event) {
        let billed = event.target.value
        this.billedTarget.value = billed
        if (billed == 'Inscription hospitalière') {
            this.billing_company_info_fieldTarget.classList.remove('d-none')
            this.billing_company_fieldTarget.classList.remove('d-none')
            this.billing_companyTarget.setAttribute("required", "");
            this.billing_address_fieldTarget.classList.remove('d-none')
            this.billing_addressTarget.setAttribute("required", "");
            this.billing_postcode_fieldTarget.classList.remove('d-none')
            this.billing_postcodeTarget.setAttribute("required", "");
            this.billing_town_fieldTarget.classList.remove('d-none')
            this.billing_townTarget.setAttribute("required", "");
            this.billing_firstname_fieldTarget.classList.remove('d-none')
            this.billing_firstnameTarget.setAttribute("required", "");
            this.billing_lastname_fieldTarget.classList.remove('d-none')
            this.billing_lastnameTarget.setAttribute("required", "");
            this.billing_phone_fieldTarget.classList.remove('d-none')
            this.billing_phoneTarget.setAttribute("required", "");
            this.billing_email_fieldTarget.classList.remove('d-none')
            this.billing_emailTarget.setAttribute("required", "");
        } else {
            this.billing_company_info_fieldTarget.classList.add('d-none')
            this.billing_company_fieldTarget.classList.add('d-none')
            this.billing_companyTarget.removeAttribute("required");
            this.billing_address_fieldTarget.classList.add('d-none')
            this.billing_addressTarget.removeAttribute("required");
            this.billing_postcode_fieldTarget.classList.add('d-none')
            this.billing_postcodeTarget.removeAttribute("required");
            this.billing_town_fieldTarget.classList.add('d-none')
            this.billing_townTarget.removeAttribute("required");
            this.billing_firstname_fieldTarget.classList.add('d-none')
            this.billing_firstnameTarget.removeAttribute("required");
            this.billing_lastname_fieldTarget.classList.add('d-none')
            this.billing_lastnameTarget.removeAttribute("required");
            this.billing_phone_fieldTarget.classList.add('d-none')
            this.billing_phoneTarget.removeAttribute("required");
            this.billing_email_fieldTarget.classList.add('d-none')
            this.billing_emailTarget.removeAttribute("required");
        }
    }

    togglePayment(event) {
        let payment_method = event.target.value
        this.payment_methodTarget.value = payment_method
        if (payment_method == "Carte Bancaire") {
            this.cb_detailsTarget.classList.remove('d-none')
        } else {
            this.cb_detailsTarget.classList.add('d-none')
        }
        if (payment_method == "Virement") {
            this.virement_detailsTarget.classList.remove('d-none')
        } else {
            this.virement_detailsTarget.classList.add('d-none')
        }
    }

    downloadFacture(event) {
        event.preventDefault()
        const doc = new jsPDF({
            compress: false,
            orientation: 'p',
            unit: 'px',
            putOnlyUsedFonts: true,
            format: 'a4'
        });

        let getContent = this.facture_templateTarget;
        doc.html(getContent, {
            width: 500,
            windowWidth: 500,
            margin: 15,
            callback: function () {
                doc.save('Facture-CBAV.pdf');
            }
        });
    }

}